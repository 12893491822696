<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.875 1.625H8.125M1.625 3.25H11.375M10.2917 3.25L9.9118 8.94795C9.85481 9.80283 9.82632 10.2303 9.64168 10.5544C9.47912 10.8397 9.23392 11.0691 8.9384 11.2123C8.60273 11.375 8.17434 11.375 7.31756 11.375H5.68244C4.82566 11.375 4.39727 11.375 4.0616 11.2123C3.76608 11.0691 3.52088 10.8397 3.35832 10.5544C3.17369 10.2303 3.14519 9.80283 3.0882 8.94795L2.70833 3.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTrash',
}
</script>
